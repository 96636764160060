
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACT_LIST, GET_DEPARTMENT_LIST } from '../store';
import { Contact, Type } from '../types';
import { ApiResponse } from '@/components/types';

const Contact = namespace('contact');

@Component({})
export default class DepartmentSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String }) type!: Type | null;
  @Prop({ type: String, default: 'select.contact' }) fieldName!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  @Contact.Getter(GET_DEPARTMENT_LIST) items!: Contact[];
  @Contact.Action(FETCH_CONTACT_LIST) fetch!: () => Promise<ApiResponse>;

  get list() {
    if (!this.type) {
      return [];
    }

    return this.items
      .filter((department) => department.types.includes(this.type as Type))
      .map((department) => {
        const name: string[] = [];

        if (department.city) {
          name.push(department.city);
        }

        if (department.additional) {
          name.push(department.additional);
        }

        return {
          ...department,
          name: `${department.name}${name.length > 0 ? ` (${name.join(', ')})` : ''}`
        };
      })
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  get label() {
    if (!this.type) {
      return 'common.department';
    }

    return `contact.type.${this.type}`;
  }

  created() {
    return this.fetch();
  }
}
