

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_DEPARTMENT } from '../store';
import { ConservatorshipDetails } from '../../conservatorship/types';
import { AddDepartment } from '../types';
import { ApiResponse } from '@/components/types';
import DepartmentTypeSelect from '../../contact/components/DepartmentTypeSelect.vue';
import DepartmentSelect from '../../contact/components/DepartmentSelect.vue';
import { createDepartment } from '../model';

const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: {
    DepartmentSelect,
    DepartmentTypeSelect
  }
})
export default class AddDepartmentDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(ADD_DEPARTMENT) addDepartment!: (params: AddDepartment) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;
  disabled: boolean = false;

  value: AddDepartment = createDepartment(this.conservatorship.id, '');

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = createDepartment(this.conservatorship.id, '');

      this.disabled = false;

      return;
    }

    setTimeout(() => {
      this.error = null;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return this.addDepartment(this.value);
  }
}
